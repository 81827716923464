export default {
  lang:'vn',
  指数差价合约: "Index CFDs",
  无需持有基础资产: "Giao dịch linh hoạt mà không cần sở hữu tài sản cơ sở – Nắm bắt cơ hội toàn cầu",
  掌控全球机遇: "",
  即刻开户: "Đăng ký ngay",
  美股指数差价合约: "Index CFDs chứng khoán Mỹ",
  点差大幅度降低: "Hưởng lợi từ chênh lệch giá giảm, tính thanh khoản tăng, và giao dịch với chi phí thấp trên các thị trường toàn cầu.",
  美国道琼斯工业平均指数: "US Dow Jones Industrial Average Index Spot CFD",
  美国标准普尔500指数: "US S&P 500 Index Spot CFD",
  美国纳斯达克100指数: "US NASDAQ-100 Index Spot CFD",
  香港恒生指数: "Hong Kong Hang Sheng Index Spot CFD",
  中国A50指数:"China A50 Index Spot CFD",
  降幅: "-Reduction Percentage (%)",
  亚洲时间: "Asian Session",
  欧洲时间: "European Session",
  美国时间: "US Session",
  品种: "Symbol",
  指数描述: "Description",
  指数点差: "Spreads in Index Points",
  开设模拟账户: "Mở tài khoản demo",
  开设真实账户: "Mở tài khoản thực",
  产品优势: "Ưu điểm sản phẩm",
  无需持有基础资产:
    "Tận hưởng chi phí thấp và đòn bẩy linh hoạt, cho phép bạn nắm bắt cơ hội trong tất cả mọi hoàn cảnh thị trường.",
  灵活高杠杆: "Đòn bẩy linh hoạt",
  杠杆高达: "Đòn bẩy lên đến 100:1, gia tăng lợi nhuận mỗi giao dịch, quản lý rủi ro một cách linh hoạt",
  放大每笔交易收益: "",
  实时弹性定价: "Định giá linh hoạt theo thời gian thực",
  根据全球市场波动实时调整: "Điều chỉnh ngay theo biến động toàn cầu, đảm bảo bạn được hưởng điều kiện giao dịch tốt nhất cho mỗi lệnh",
  确保您每笔交易享受最优交易条件: "",
  极致的交易速度: "Tốc độ giao dịch cực nhanh",
  平均执行速度20毫秒: "Tốc độ thực hiện trung bình 20 mili giây, giúp bạn nắm bắt cơ hội trên thị trường",
  助您抢占市场先机: "",
  完全透明的交易: "Giao dịch thị trường minh bạch",
  无隐藏费用或额外成本: "Không có phí ẩn hay chi phí bổ sung, đảm bảo tính minh bạch và công bằng cho mỗi giao dịch",
  确保您每笔交易的透明和公平: "",
  全球指数交易深度: "Độ sâu thị trường toàn cầu",
  查看所有产品: "Tất cả sản phẩm",
  指数差价合约相关问题: "Các câu hỏi liên quan đến Index CFDs",
  list: [
    {
      title: "1. Index CFD là gì?",
      content: `Index CFDs thường đề cập đến hợp đồng chênh lệch trên
                        các chỉ số chứng khoán, phản ánh sự thay đổi giá của các
                        chỉ số chứng khoán và chịu lỗ hoặc lãi do sự biến động.
                        So với việc đầu tư vào các cổ phiếu riêng lẻ, các chỉ số
                        chứng khoán đại diện cho xu hướng của một nhóm cổ phiếu
                        tiêu biểu, phục vụ như một thước đo cho một ngành cụ thể
                        hoặc thậm chí toàn bộ nền kinh tế của một quốc gia. Ví
                        dụ, chỉ số DAX 30 của Đức bao gồm 30 cổ phiếu blue-chip
                        tiêu biểu, phản ánh hiệu suất tổng thể của thị trường
                        chứng khoán Đức. Thông qua Index CFD, các đánh giá phức
                        tạp của các cổ phiếu riêng lẻ có thể được đơn giản hóa
                        để phân tích tâm lý thị trường tổng thể, phân tán rủi ro
                        và tránh sự biến động giá cực đoan của các cổ phiếu
                        riêng lẻ.`,
    },
    {
      title:"2. Các sản phẩm có thể giao dịch với Index CFDs?",
      content:`Index CFDs cho phép các nhà giao dịch dự đoán về các chỉ
                        số chứng khoán toàn cầu. EBC chọn các chỉ số toàn cầu
                        chính, bao gồm Chỉ số Dow Jones, Chỉ số DAX 30, Chỉ số
                        STOXX 50, Chỉ số S&P 500, Chỉ số Nikkei 225 và nhiều hơn
                        nữa. Với chỉ một tài khoản EBC, các nhà giao dịch có thể
                        xây dựng các vị thế trên nhiều thị trường toàn cầu và
                        đạt được thành công với các mức chênh lệch cạnh tranh và
                        đòn bẩy linh hoạt của EBC.`
    },
    {
      title:"3. Đòn bẩy trong giao dịch Index CFDs là gì?",
      content: `Index CFDs cho phép giao dịch các chỉ số chứng khoán
                        toàn cầu.<br />
                        EBC cung cấp đòn bẩy lên đến 100x cho giao dịch Index
                        CFDs, đây là tỷ lệ đòn bẩy tối đa được phép trong khuôn
                        khổ tuân thủ và quy định. Điều này cân nhắc giữa sự an
                        toàn và hiệu quả vốn.`
    },
    {
      title:"4. Kích thước hợp đồng Index CFDs được xác định ra sao?",
      content:`Các chỉ số khác nhau tương ứng với các kích thước hợp
                        đồng khác nhau.<br />
                        EBC cung cấp CFD trên các chỉ số chứng khoán toàn cầu
                        chính, với kích thước hợp đồng là 10 cho 100GBP (UK FTSE
                        100), 200AUD (Australia S&P/ASX 200), CNIUSD (China A50
                        Index), D30EUR (Germany DAX 30), E50EUR (Europe STOXX
                        50), F40EUR (France CAC 40), HSIUSD (Hong Kong 50
                        Index), NASUSD (US Nasdaq 100 Index), và U30USD (US
                        Stock 30 Index). Đối với 225JPY (Nikkei 225 Index) và
                        SPXUSD (S&P 500 Index), kích thước hợp đồng là 100.<br />
                        Kích thước hợp đồng tối thiểu cho Index CFDs là 0.1 lot.`
    },
    {
      title:"5. Những lợi ích giao dịch Index CFDs?",
      content: `Hiệu quả vốn gia tăng: Giao dịch chỉ số thường liên quan
                        đến đòn bẩy thấp hơn, nhưng Index CFDs cho phép tham gia
                        với ít vốn hơn. Tuy nhiên, điều quan trọng là quản lý vị
                        thế của bạn khi tham gia giao dịch có đòn bẩy.<br />

                        Giao dịch hai chiều: Một chỉ số chứng khoán phản ánh
                        hướng tổng thể của một thị trường chứng khoán. Thông qua
                        Index CFDs, các nhà giao dịch có thể thực hiện cả giao
                        dịch mua và bán, cung cấp khả năng sinh lợi cao hơn.<br />

                        Đa dạng hóa rủi ro: Các chỉ số chứng khoán thường được
                        cấu thành từ một lựa chọn các cổ phiếu tiêu biểu nhất
                        trong một thị trường. Sự biến động của một cổ phiếu đơn
                        lẻ có thể không ảnh hưởng trực tiếp đến chỉ số, từ đó
                        giảm thiểu rủi ro thị trường hiệu quả.`
    },
    {
      title:"6. Làm thế nào để học và giao dịch được Index CFDs?",
      content:`Vì Index CFDs phản ánh xu hướng của một nhóm cổ phiếu,
                        nên sự chú ý nên tập trung vào thị trường tổng thể thay
                        vì các thành phần cụ thể. Thông thường, các chỉ số chứng
                        khoán có mối liên hệ trực tiếp với chính sách tiền tệ
                        của ngân hàng trung ương và tình hình kinh tế của một
                        quốc gia. Ví dụ, nếu một ngân hàng trung ương thực hiện
                        chính sách tiền tệ thắt chặt, lo ngại về việc thiếu
                        thanh khoản có thể làm giảm thị trường chứng khoán.
                        Tương tự, dữ liệu kinh tế kém có thể làm giảm kỳ vọng
                        đầu tư của thị trường, từ đó ảnh hưởng đến thị trường
                        chứng khoán.`
    }
  ],
  每个认真交易的人:"Mỗi một nhà giao dịch nghiêm túc đều xứng đáng được coi trọng"
};
