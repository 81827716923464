export default {
	"headlist": [{
		"text": "ホーム",
		"url": "/"
	}, {
		"text": "取引",
		"tips": "",
		"img": "11",
		"menu": [{
			"tit": "アカウントと条件",
			"list": [ {
				"text": "取引口座",
				"url": "/TradingAccount"
			}, {
				"text": "取引商品",
				"url": "/tradingproduct"
			}, {
				"text": "レバレッジマージン",
				"url": "/leverage-margin"
			},{
				"text": "入出金",
				"url": "/funding"
			}, {
				"text": "配当",
				"url": "/dividend"
			}]
		},  {
			"tit": "取引ツール",
			"list": [{
				"text": "MT4 ダウンロード",
				"url": "/MT4"
			},{
				"text": "MT5 ダウンロード",
				"url": "/MT5"
			},{
				"text": "MT4 取引ツール",
				"url": "/tools"
			}, {
				"text": "PAMM",
				"url": "/PAMM"
			}, {
				"text": "市場分析",
				"url": "/market-analysis"
			}]
		}]
	}, {
		"text": "EBC研究所",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "学習センター",
				"url": "/LearningCenter"
			}, {
				"text": "データセンター",
				"url": "/lab"
			},] 
		}]
	}, {
		"text": "キャンペーン",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [
			// 	{
			// 	"text": "トレーディングコンテスト",
			// 	"url": "/win"
			// },
			// {
			// 	"text": "最高の取引環境キャンペーン",
			// 	"url": "/no1"
			// },
			{
				"text": "クリエイターインセンティブプログラム",
				"url": "/cfa"
			}]
		}]
	}, {
		"text": "EBCについて",
		"tips": "",
		"img": "55",
		"menu": [ {
			"tit": "EBCについて",
			"list": [{
				"text": "WHY EBC",
				"url": "/about-us"
			},{
				"text": "Sustainability & Impact",
				"url": "/ESG"
			}, {
				"text": "よくある質問",
				"url": "https://support.ebchelp.com/hc/ja-jp"
			}]
		},{
			"tit": "パートナー",
			"list": [{
				"text": "パートナーシップ",
				"url": "/affiliate-programmes"
			},{
				"text": "流動性サービス",
				"url": "/service"
			},{
				"text": "基盤技術",
				"url": "/technology"
			},
			]
		}]
	}],
	"FCA": "FCAアカウント",
	"login": "ログイン",
	"zhuce": "口座開設",
	"from0tohero_text": "コピートレード",
	"from0tohero_url": "/from0tohero",
	"faq_url": "https://support.ebchelp.com/hc/ja-jp",
}

