export default {
  lang:'en',
  指数差价合约: "Index CFDs",
  无需持有基础资产: "Trade Flexibly Without Owning the Underlying Asset – Seize Global Opportunities",
  掌控全球机遇: "",
  即刻开户: "Open Account",
  美股指数差价合约: "US Stock Index CFDs",
  点差大幅度降低: "Benefit from reduced spreads, increased liquidity, and low-cost trading across global markets.",
  美国道琼斯工业平均指数: "US Dow Jones Industrial Average Index Spot CFD",
  美国标准普尔500指数: "US S&P 500 Index Spot CFD",
  美国纳斯达克100指数: "US NASDAQ-100 Index Spot CFD",
  香港恒生指数: "Hong Kong Hang Sheng Index Spot CFD",
  中国A50指数:"China A50 Index Spot CFD",
  降幅: "-Reduction Percentage (%)",
  亚洲时间: "Asian Session",
  欧洲时间: "European Session",
  美国时间: "US Session",
  品种: "Symbol",
  指数描述: "Description",
  指数点差: "Spreads in Index Points",
  开设模拟账户: "Open Demo Account",
  开设真实账户: "Open Real Account",
  产品优势: "Advantages",
  无需持有基础资产:
    "Enjoy low-cost, flexible leverage that allows you to capture opportunities in both rising and falling markets.",
  灵活高杠杆: "Flexible Leverage",
  杠杆高达: "Leverage up to 100:1, amplifying each trade’s returns while allowing you to manage risk effectively.",
  放大每笔交易收益: "",
  实时弹性定价: "Real-Time Dynamic Pricing",
  根据全球市场波动实时调整: "Our prices adjust instantly to global market fluctuations, ensuring you always get the best trading conditions.",
  确保您每笔交易享受最优交易条件: "",
  极致的交易速度: "Extreme Fast Trade",
  平均执行速度20毫秒: "With an average execution speed of 20 milliseconds, you can act quickly to stay ahead of the market.",
  助您抢占市场先机: "",
  完全透明的交易: "Complete Transparent in Trade",
  无隐藏费用或额外成本: "No hidden fees or extra costs, ensuring that every transaction is transparent and fair.",
  确保您每笔交易的透明和公平: "",
  全球指数交易深度: "Global Market Depth",
  查看所有产品: "All Products",
  指数差价合约相关问题: "Index CFDs Related Questions",
  list: [
    {
      title: "1. What is Index CFD?",
      content: ` Index CFD generally refers to Contract for Difference on
                        stock indices, reflecting the price changes of stock
                        indices and incurring profit or loss due to fluctuation.
                        Compared to individual stock investments, stock indices
                        represent the trends of a group of representative
                        stocks, serving as a barometer for a specific industry
                        or even the overall economy of a country. For example,
                        the Germany DAX 30 index consists of 30 representative
                        blue-chip stocks, reflecting the overall performance of
                        the German stock market. Through index CFDs, complex
                        assessments of individual stocks can be simplified to
                        analyse the overall market sentiment, diversify risks,
                        and avoid extreme price fluctuations in individual
                        stocks.`,
    },
    {
      title:"2. What can be traded with index CFDs?",
      content:` Index CFDs allow traders to speculate on global stock
                        indices. EBC selects major global indices, including the
                        Dow Jones Index, DAX 30 Index, STOXX 50 Index, S&P 500
                        Index, Nikkei 225 Index, and more. With just one EBC
                        account, traders can build exposures to numerous markets
                        globally and achieve success with EBC's competitive
                        spreads and flexible leverage.`
    },
    {
      title:"3. What is Leverage for Index CFD Trading?",
      content: `
       Index CFDs allow trading of global stock indices<br />

                        EBC provides up to 100x leverage for index CFD trading,
                        which is the maximum leverage ratio allowed within
                        compliance and regulatory limits. This takes into
                        consideration a balance between safety and capital
                        efficiency.`
    },
    {
      title:" 4. How is the Contract Size for Index CFDs Determined?",
      content:`Different indices correspond to different contract
                        sizes. <br />
                        EBC offers CFDs on major global stock indices, with
                        contract sizes of 10 for 100GBP (UK FTSE 100), 200AUD
                        (Australia S&P/ASX 200), CNIUSD (China A50 Index),
                        D30EUR (Germany DAX 30), E50EUR (Europe STOXX 50),
                        F40EUR (France CAC 40), HSIUSD (Hong Kong 50 Index),
                        NASUSD (US Nasdaq 100 Index), and U30USD (US Stock 30
                        Index). For 225JPY (Nikkei 225 Index) and SPXUSD (S&P
                        500 Index), the contract size is 100. <br />
                        The minimum contract size for index CFDs is 0.1 lots.`
    },
    {
      title:"5. What are the Advantages of Trading Index CFD?",
      content: `Increased capital efficiency: Index trading usually
                        involves lower leverage, but index CFDs allow
                        participation with less capital. However, it's crucial
                        to manage your position when engaging in leveraged
                        trading. <br />

                        Bidirectional trading: A stock index reflects the
                        overall direction of a stock market. Through index CFDs,
                        traders can engage in both long and short trades,
                        providing greater profit potential. <br />

                        Risk diversification: Stock indices are often composed
                        of a selection of the most representative stocks in a
                        market. The movement of a single stock may not directly
                        impact the index, effectively diversifying market risk.`
    },
    {
      title:" 6. How to Learn and Trade Index CFDs?",
      content:` As index CFDs reflect the trends of a group of stocks,
                        attention should be focused on the overall market rather
                        than specific components. Generally, stock indices are
                        directly correlated with central bank monetary policies
                        and a country's economic situation. For example, if a
                        central bank implements a contractionary monetary
                        policy, concerns about a lack of liquidity may suppress
                        the stock market. Similarly, poor economic data can
                        lower market investment expectations, thereby affecting
                        the stock market.`
    }
  ],
  每个认真交易的人:"Exceptional Brilliant Care for Every Committed Trader"
};
