<template>
	<div class="home" :class="$t('CFooters.className')">
		<ss-header mode="Home" bg="rgba(38, 5, 5, 1)"></ss-header>
		<full-page :options="options" ref="fullpage">
			<div class="section">
				<banner-img></banner-img>
			</div>
			<div class="section section1">
				<div class="fp-overflow" tabindex="-1">
					<div class="container">
						<div class="row">
							<div class="col-md-1"></div>
							<div class="col-md-5">
								<p class="title cn-title1">{{$t('Index.pages2.title')}}</p>
								<p class="line"></p>
								<p class="desc" style="width: 90%;">{{$t('Index.pages2.title1')}}</p>
								<router-link class="learn-more"
									:to="!$store.state.suffix ? '/tradingproduct' :('/' + $store.state.suffix + '/tradingproduct')">
									{{$t('Index.pages2.btn')}}
								</router-link>
							</div>
							<div class="col-md-6 market">
								<ul>
									<li id="EURUSD">
										<span class="name">EURUSD</span>
										<span class="float"
											:class="{'red': forex.EURUSD.percentage < 0,'green':forex.EURUSD.percentage > 0}">{{forex.EURUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.EURUSD.percentage < 0}">
											{{tear(forex.EURUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.EURUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.EURUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.EURUSD.percentage < 0}">
											{{tear(forex.EURUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.EURUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" href="https://client.ebccrm.com/signup/index?hl=zh">BUY</a>
										<a class="sell" href="https://client.ebccrm.com/signup/index?hl=zh">SELL</a>
									</li>
									<li id="XAUUSD">
										<span class="name">XAUUSD</span>
										<span class="float"
											:class="{'red': forex.XAUUSD.percentage < 0,'green':forex.XAUUSD.percentage > 0}">{{forex.XAUUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.XAUUSD.percentage < 0}">
											{{tear(forex.XAUUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.XAUUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.XAUUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.XAUUSD.percentage < 0}">
											{{tear(forex.XAUUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.XAUUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" href="https://client.ebccrm.com/signup/index?hl=zh">BUY</a>
										<a class="sell" href="https://client.ebccrm.com/signup/index?hl=zh">SELL</a>
									</li>
									<li id="USDJPY">
										<span class="name">USDJPY</span>
										<span class="float"
											:class="{'red': forex.USDJPY.percentage < 0,'green':forex.USDJPY.percentage > 0}">{{forex.USDJPY.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.USDJPY.percentage < 0}">
											{{tear(forex.USDJPY.bid_price)[0] || '--'}}<span>.{{tear(forex.USDJPY.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.USDJPY.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.USDJPY.percentage < 0}">
											{{tear(forex.USDJPY.ask_price)[0] || '--'}}<span>.{{tear(forex.USDJPY.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="bottom">
						<div class="container">
							<div class="row">
								<div class="col-md-3">
									<div><img src="../assets/image/index/section1-icon1.png" alt=""></div>
									<p>{{$t('Index.pages2.text')}}</p>
								</div>
								<div class="col-md-3">
									<div><img src="../assets/image/index/section1-icon2.png" alt=""></div>
									<p>{{$t('Index.pages2.text1')}}</p>
								</div>
								<div class="col-md-3">
									<div><img src="../assets/image/index/section1-icon3.png" alt=""></div>
									<p>{{$t('Index.pages2.text2')}}</p>
								</div>
								<div class="col-md-3">
									<div><img src="../assets/image/index/section1-icon4.png" alt=""></div>
									<p>{{$t('Index.pages2.text3')}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section section2">
				<div class="fp-overflow" tabindex="-1">
					<div class="container">
						<div class="row">
							<div class="col-md-1"></div>
							<div class="col-md-5 index-section2-col-md-5">
								<p class="title cn-title2">{{$t('Index.pages3.title')}}</p>
								<p class="line"></p>
								<p class="desc">{{$t('Index.pages3.title1')}}</p>
								<router-link class="learn-more"
									:to="!$store.state.suffix ? '/about-us' :('/' + $store.state.suffix + '/about-us')">
									{{$t('Index.pages3.btn')}}
								</router-link>
							</div>
							<div class="col-md-6 d1">
								<div style="animation-duration: 2s">
									<img src="../assets/image/index/index-new-01.svg" alt="">
									<p class="p1">{{$t('Index.pages3.text')}}</p>
									<p class="p2" v-html="$t('Index.pages3.text1')"></p>
								</div>
								<div style="animation-duration: 2s">
									<img src="../assets/image/index/index-new-03.svg" alt="">
									<p class="p1">{{$t('Index.pages3.text4')}}</p>
									<p class="p2" v-html="$t('Index.pages3.text5')"></p>
								</div>
								<div style="animation-duration: 2s">
									<img src="../assets/image/index/index-new-02.svg" alt="">
									<p class="p1">{{$t('Index.pages3.text2')}}</p>
									<p class="p2" v-html="$t('Index.pages3.text3')"></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="index-security section">
				<div class="fp-overflow" tabindex="-1">
					<div class="container">
						<div class="row">
							<div class="col-md-1"></div>
							<div class="col-md-7">
								<img class="index-security-right-m" src="../assets/image/index/index-security-right.png" alt="">
								<p class="title cn-title2">{{$t('Index.pages9.title')}}</p>
								<p class="line"></p>
								<p class="desc">{{$t('Index.pages9.title1')}}</p>
								<router-link class="learn-more"
									:to="'/security'">
									{{$t('Index.pages9.btn')}}
								</router-link>
							</div>
							<div class="col-md-4 security-right-box">
								<img class="index-security-right" src="../assets/image/index/index-security-right.png" alt="">
							</div>
							<div class="index-security-bottom">
								<router-link :to="'/LearningCenterInfo?id=LEARN-196-367'"><img src="../assets/image/index/index-security-bottom1.png" alt=""></router-link>
								<router-link :to="'/LearningCenterInfo?id=LEARN-770-2FF'"><img src="../assets/image/index/index-security-bottom2.png" alt=""></router-link>
								<router-link :to="'/LearningCenterInfo?id=LEARN-369-368'"><img src="../assets/image/index/index-security-bottom3.png" alt=""></router-link>
								<router-link :to="'/LearningCenterInfo?id=LEARN-2C9'"><img src="../assets/image/index/index-security-bottom4.png" alt=""></router-link>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="section section3">
				<div class="fp-overflow" tabindex="-1">
					<div class="container">
						<div class="row r1">
							<div class="col-md-1"></div>
							<div class="col-md-5">
								<p class="title">{{$t('Index.pages4.title')}}</p>
								<p class="line"></p>
								<p class="desc">
									{{$t('Index.pages4.title1')}}
								</p>
								<router-link class="learn-more"
									:to="!$store.state.suffix ? '/technology' :('/' + $store.state.suffix + '/technology')">
									{{$t('Index.pages4.btn')}}
								</router-link>
							</div>
							<div class="col-md-6">
								<img :src="require(`../assets/image/index/Fintech-network-${$t('Index.lang')}.png`)" />
							</div>
						</div>
					</div>
					<div class="bottom">
						<div class="container">
							<div class="row fintech-num">
								<div class="col-md-3">
									<span>
										<animate-number from="0" to="1000" :formatter="formatter" v-if="pageShow_3">
										</animate-number>
									</span>
									<p>{{$t('Index.pages4.text')}}</p>
								</div>
								<div class="col-md-3"><span><animate-number from="0" to="20" :formatter="formatter"
											v-if="pageShow_3">
										</animate-number><em>ms</em></span>
									<p>{{$t('Index.pages4.text1')}}</p>
								</div>
								<div class="col-md-3"><span><animate-number from="0" to="98.75" :formatter="formatter"
											v-if="pageShow_3">
										</animate-number><em>%</em></span>
									<p>{{$t('Index.pages4.text2')}}</p>
								</div>
								<div class="col-md-3"><span>0.0<em>PIPS</em></span>
									<p>{{$t('Index.pages4.text3')}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section section4">
				<div class="fp-overflow" tabindex="-1">
					<div class="container">
						<div class="row">
							<div class="col-md-1"></div>
							<div class="col-md-4">
								<p class="title">{{$t('Index.pages5.title')}}</p>
								<p class="line"></p>
								<p class="desc">{{$t('Index.pages5.title1')}}</p>
								<router-link class="learn-more"
									:to="!$store.state.suffix ? '/technology' :('/' + $store.state.suffix + '/technology')">
									{{$t('Index.pages5.btn')}}
								</router-link>
							</div>
							<div class="col-md-7">
								<img class="black-box-pc"
									:src="require(`../assets/image/index/Black-Box-img-${$t('Index.lang')}.png`)" />
								<img :src="require(`../assets/image/index/Black-Box-img-${$t('Index.lang')}-m.png`)"
									class="black-box-m" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section section5">
				<div class="fp-overflow" tabindex="-1">
					<div class="container">
						<div class="row">
							<div class="col-md-1"></div>
							<div class="col-md-4">
								<p class="title">{{$t('Index.pages6.title')}}</p>
								<p class="line"></p>
								<p class="desc">{{$t('Index.pages6.title1')}}</p>
								<router-link class="learn-more"
									:to="!$store.state.suffix ? '/technology' :('/' + $store.state.suffix + '/technology')">
									{{$t('Index.pages6.btn')}}
								</router-link>
							</div>
							<div class="col-md-7">
								<img src="../assets/image/index/Private-Room-img.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section section6">
				<div class="fp-overflow" tabindex="-1">
					<div class="container">
						<div class="row">
							<div class="col-md-1"></div>
							<div class="col-md-5">
								<p class="title">{{$t('Index.pages7.title')}}</p>
								<p class="line"></p>
								<p class="desc">{{$t('Index.pages7.title1')}}</p>
								<router-link class="learn-more"
									:to="!$store.state.suffix ? '/partner' :('/' + $store.state.suffix + '/partner')">
									{{$t('Index.pages7.btn')}}
								</router-link>
							</div>
							<div class="col-md-6">
								<img src="../assets/image/index/partner.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section8 section" id="Honor">
				<div class="fp-overflow" tabindex="-1">
					<div class="container">
						<p class="title">{{$t('WhyEBC.screen4.title')}}</p>
						<p class="line"></p>
						<div class="row index-honor-list">
							<div class="swiper-wrapper slide-box">
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-647-43B')" :title="$t('WhyEBC.screen4.desc1')">
									<img src="../assets/image/index/index-honor11.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc1')">{{$t('WhyEBC.screen4.desc1')}}</p>
								</div>
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-647-43B')" :title="$t('WhyEBC.screen4.desc2')">
									<img src="../assets/image/index/index-honor22.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc2')">{{$t('WhyEBC.screen4.desc2')}}</p>
								</div>
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2DC')" :title="$t('WhyEBC.screen4.desc4')">
									<img src="../assets/image/index/index-honor33.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc4')">{{$t('WhyEBC.screen4.desc4')}}</p>
								</div>
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2DC')" :title="$t('WhyEBC.screen4.desc5')">
									<img src="../assets/image/index/index-honor44.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc5')">{{$t('WhyEBC.screen4.desc5')}}</p>
								</div>
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2E5')" :title="$t('WhyEBC.screen4.desc3')">
									<img src="../assets/image/index/index-honor55.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc3')">{{$t('WhyEBC.screen4.desc3')}}</p>
								</div>
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-30-363')" :title="$t('WhyEBC.screen4.desc6')">
									<img src="../assets/image/index/index-honor66.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc6')">{{$t('WhyEBC.screen4.desc6')}}</p>
								</div>
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-559-34F')" :title="$t('WhyEBC.screen4.desc7')">
									<img src="../assets/image/index/index-honor77.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc7')">{{$t('WhyEBC.screen4.desc7')}}</p>
								</div>
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-486-3D7')" :title="$t('WhyEBC.screen4.desc8')">
									<img src="../assets/image/index/index-honor88.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc8')">{{$t('WhyEBC.screen4.desc8')}}</p>
								</div>
								<div class="col-md-4 swiper-slide" @click="jumPage('LEARN-518-4C3')" :title="$t('WhyEBC.screen4.desc9')">
									<img src="../assets/image/index/index-honor99.png" alt="">
									<p v-if="$t('WhyEBC.screen4.desc9')">{{$t('WhyEBC.screen4.desc9')}}</p>
								</div>
							</div>    
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="section section7">
				<div class="fp-overflow">
					<div class="container">
						<ss-banner :newData="newData" v-if="newData.length != 0"></ss-banner>
						<router-link class="learn-more" to="/LearningCenter">
							{{$t('Index.pages8.btn')}}
						</router-link>
					</div>
				</div>
			</div> -->
			<div class="section index-footer">
				<new-footer></new-footer>
			</div>
		</full-page>
		<ss-footer></ss-footer>
		<div class="left-nav-fixed" :class="{'active':pageIndex != 0 && pageIndex < 9}">
			<ul>
				<li v-for="(item,index) in $t('Index.leftNav')" :key="index">
					<a href="#" @click="jumpPage(index)"
						:class="{'active':pageIndex == (index + 1)}"><span></span>{{item.name}}</a>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	import WOW from 'wowjs';
	import md5 from 'js-md5';
	import 'swiper/css/swiper.css'
  	import Swiper from 'swiper'
	import {
		mapState
	} from "vuex";
	export default {
		name: '',
		data() {
			return {
				seoInfo: "",
				options: {
					licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
					//是否显示导航，默认为false
					afterLoad: this.afterLoad,
					slideSelector: '.slide',
					loopHorizontal: true,
					controlArrows: false,
					//为每个section设置背景色
				},
				pageIndex: 0,
				pageShow_3: false,
				forex: {
					"XAUUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"EURUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"USDJPY": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
				},
				XAGUSDask: "",
				EURUSDask: "",
				USDJPYask: "",
				newData: [],
				time: "",
				isMobil: "",
				slideIndex: 0,
				status:false,
				swiper: null,
				num: 3
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		computed: {
			...mapState({
				Lan: (state) => state.Lan,
			}),
		},
		created() {
			
			this.isMobil = this.isMobile()
			if (this.isMobile()) {
				this.options.autoScrolling = false
				this.options.scrollOverflow = false
			} else {
				this.options.autoScrolling = true
				this.options.scrollOverflow = true
			}
			this.getPortfolio()
			setTimeout(()=>{
				//this.getNewData()
				this.$getSeoInfo({
					lang: this.Lan,
					path: "/"
				}).then(res => {
					this.seoInfo = res
				})
			},500)
		},
		mounted() {
			this.status = true
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: false,
			});
			wow.init();
			if (!this.isMobile()) {
				this.swiper = new Swiper('.index-honor-list', {
				lazy: {
					loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
					loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
				},
				zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
					maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
					minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
					toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
				},
				navigation: { // 如果需要前进后退按钮
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				scrollbar: { // 如果需要滚动条
					el: '.swiper-scrollbar',
					hide: true // 滚动条是否自动隐藏，默认false
				},
				slidesPerView: 4,
				mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
				direction: 'horizontal', // 滑动方向
				speed: 6000, // 切换速度，自动滑动开始到结束的时间
				grabCursor: false, // 悬浮时鼠标样式切换
				spaceBetween: 60,
				centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
				effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
				cubeEffect: { // cube效果参数
					slideShadows: false, // 是否开启slide投影，默认true
					shadow: false, // 是否开启投影，默认true
					shadowOffset: 20, // 投影距离。默认 20，单位px。
					shadowScale: 1 // 投影缩放比例。默认0.94。
				},
				// autoplay: true, // 启动自动切换，等同于以下设置
				autoplay: {
					delay: 0,
					disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
					waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
				},
				loop: true // 循环模式选项
				})
				// 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
				this.swiper.el.onmouseover = function() { // 鼠标覆盖停止自动切换
				this.swiper.autoplay.stop()
				}
				this.swiper.el.onmouseout = function() { // 鼠标离开开始自动切换
				this.swiper.autoplay.start()
				}
			}else{
				this.swiper = new Swiper('.index-honor-list', {
				lazy: {
					loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
					loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
				},
				zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
					maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
					minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
					toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
				},
				navigation: { // 如果需要前进后退按钮
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				scrollbar: { // 如果需要滚动条
					el: '.swiper-scrollbar',
					hide: true // 滚动条是否自动隐藏，默认false
				},
				slidesPerView: 1.6,
				spaceBetween: 50,
				centeredSlides: true,
				mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
				direction: 'horizontal', // 滑动方向
				speed: 6000, // 切换速度，自动滑动开始到结束的时间
				grabCursor: false, // 悬浮时鼠标样式切换
				centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
				effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
				cubeEffect: { // cube效果参数
					slideShadows: false, // 是否开启slide投影，默认true
					shadow: false, // 是否开启投影，默认true
					shadowOffset: 20, // 投影距离。默认 20，单位px。
					shadowScale: 1 // 投影缩放比例。默认0.94。
				},
				// autoplay: true, // 启动自动切换，等同于以下设置
				autoplay: {
					delay: 0,
					disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
					waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
				},
				loop: true // 循环模式选项
				})
				// 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
				this.swiper.el.onmouseover = function() { // 鼠标覆盖停止自动切换
				this.swiper.autoplay.stop()
				}
				this.swiper.el.onmouseout = function() { // 鼠标离开开始自动切换
				this.swiper.autoplay.start()
				}
			}
		},
		destroyed() {
		},
		methods: {
			jumPage(id) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: id
					}
				})
			},
			formatter(num) {
				if (num === 98.75) {
					return num.toFixed(2)
				} else if (num === 0) {
					return num.toFixed(1)
				} else {
					return num.toFixed(0)
				}

			},
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			tear(item) {
				let data = JSON.stringify(item)
				return data.split('.')
			},
			getNewData() {
				let parms;
				if (this.Lan == 'zh_CN') {
					parms = 'zh_CN'
				} else {
					parms = 'en_US'
				}
				this.$http('/ebc/api/getLearnCategory/run', {
					lang: parms
				}).then(res => {
					this.newData = res.data.data[0].info
				})
			},
			//init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
			init(url) {
				this.ws = new WebSocket(url)

				//监听是否连接成功
				this.ws.onopen = () => {
					//连接成功则发送一个数据
					this.ws.send('连接成功');
				}

				//接听服务器发回的信息并处理展示
				this.ws.onmessage = (data) => {
					if (data.data == 'Ws connected success！' || data.data == '连接成功') return
					let info = JSON.parse(data.data)
					if (info.tick.length == 0) return
					info.tick.forEach(o => {
						o.chadian = this.getCount(o.ask_price * 1, o.bid_price * 1)
					})
					if (info.symbol == 'XAUUSD') {
						this.forex.XAUUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.XAGUSDask
						this.XAGUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.XAUUSD.percentage = float
						if (float == 0) {
							this.forex.XAUUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.XAUUSD.num = float + '%'
							} else {
								this.forex.XAUUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'EURUSD') {
						this.forex.EURUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.EURUSDask
						this.EURUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.EURUSD.percentage = float
						if (float == 0) {
							this.forex.EURUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.EURUSD.num = float + '%'
							} else {
								this.forex.EURUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'USDJPY') {
						this.forex.USDJPY = info.tick[0]
						let float = info.tick[0].ask_price - this.USDJPYask
						this.USDJPYask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.USDJPY.percentage = float
						if (float == 0) {
							this.forex.USDJPY.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.USDJPY.num = float + '%'
							} else {
								this.forex.USDJPY.num = "+" + float + '%'
							}
						}
					}
				}

				//监听连接关闭事件
				this.ws.onclose = () => {
					//监听整个过程中websocket的状态
					console.log('ws连接状态：' + this.ws.readyState);
				}

				//监听并处理error事件
				this.ws.onerror = function(error) {
					console.log(error);
				}
			},
			getCount(idx1, idx2) {
				const r = /^\+?[1-9][0-9]*$/; // 正整数
				// 如果判断不符合正则，则不是正整数不能提交
				let num = 0;
				let a1 = 0
				let a2 = 0
				if (!r.test(idx1)) {
					a1 = idx1.toString().split(".")[1].length;
				}
				if (!r.test(idx2)) {
					a2 = idx2.toString().split(".")[1].length;
				}
				if (a1 > a2) {
					if (a1 === 1) {
						num = idx1 * 10 - idx2 * 10
					} else if (a1 === 2) {
						num = idx1 * 100 - idx2 * 100
					} else if (a1 === 3) {
						num = idx1 * 1000 - idx2 * 1000
					} else if (a1 === 4) {
						num = idx1 * 10000 - idx2 * 10000
					} else if (a1 === 5) {
						num = idx1 * 100000 - idx2 * 100000
					}
				} else {
					if (a2 === 1) {
						num = idx1 * 10 - idx2 * 10
					} else if (a2 === 2) {
						num = idx1 * 100 - idx2 * 100
					} else if (a2 === 3) {
						num = idx1 * 1000 - idx2 * 1000
					} else if (a2 === 4) {
						num = idx1 * 10000 - idx2 * 10000
					} else if (a2 === 5) {
						num = idx1 * 100000 - idx2 * 100000
					}
				}
				return num.toFixed(0);
			},
			getPortfolio() {
				let time = new Date().getMilliseconds()
				this.$http('/ebc/api/getToken/run', {
					date: time,
					CheckSum: md5(time + "YISENCE")
				}).then(res => {
					let url = "wss://pricecloud.eappservice.com/ws?token=" + res.data.data
					this.init(url)
				})
			},
			jumpPage(idx) {
				this.pageIndex = idx + 1
				let num = idx + 2
				this.$refs.fullpage.api.moveTo(num)
			},
			afterLoad(anchors, item) {
				this.pageShow_3 = false
				if (item.index === 0) {

				} else if (item.index === 4) {
					this.pageShow_3 = true
				}
				this.pageIndex = item.index
			},
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			isScrollOverflow() {

			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/css/index.css';

	.home {
		font-family: 'Noto Serif SC';
		&.vi-box {
			font-family: Arial, Helvetica, sans-serif !important;
		}
		&.th-box {
			font-family: "Thai";
		}
		.left-nav-fixed {
			position: fixed;
			left: 4%;
			top: 26%;
			display: none;

			&.active {
				display: block
			}
		}

		.left-nav-fixed ul {
			border-left: 1px solid #727272;
			height: 365px;
			padding-top: 18px;
		}


		.index-footer{
			background-color: #080E1C;
			.c-footer{
				height: auto !important;
				.fp-overflow {
					height: auto !important;
				}
			}
		}

		.left-nav-fixed li {
			padding: 18px;
			position: relative;
		}

		.left-nav-fixed a {
			color: #b3b3b3;
			font-size: 18px;
		}

		.left-nav-fixed a.active {
			color: #fff;
		}

		.left-nav-fixed a.active span {
			display: inline-block;
		}

		.left-nav-fixed span {
			position: absolute;
			left: -2.5px;
			border: 2px #fff solid;
			display: inline-block;
			height: 36px;
			top: 9px;
			display: none;
		}

		.fp-overflow {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		.page-screen-0 {
			width: 100%;
			overflow: hidden;
			position: relative;

			&.wap {
				display: none;
				img{
					display: block;
				}
			}
		
			&-prev {
				position: absolute;
				top: 45%;
				left: 40px;
				z-index: 100;
				cursor: pointer;

				img {
					width: 60%;
				}
			}

			&-next {
				position: absolute;
				top: 45%;
				right: 40px;
				z-index: 100;
				cursor: pointer;

				img {
					width: 60%;
				}
			}

			&-slidesNav {
				position: absolute;
				bottom: 80px;
				left: 0;
				right: 0;
				display: flex;
				justify-content: center;
				z-index: 100;

				li {
					a {
						width: 10px;
						height: 10px;
						border-radius: 5px;
						background-color: rgba(#fff, 0.6);
						display: block;

						&.active {
							background-color: #fff;
						}
					}

					&:nth-child(2) {
						a {
							margin: 0 20px;
						}
					}
				}
			}

			&__video {
				position: absolute;
				left: 45%;
				top: 0;
				width: 100%;
				min-width: 0px;
				transform: translateX(-50%);
			}

			&__box {
				position: absolute;
				top: 40%;
				text-align: center;
				width: 100%;
			}

			&__title {
				font-size: 18px;
				line-height: 1.5;
				color: #ffffff;
				opacity: 0.6;
				margin-bottom: 30px;

				&-en {
					font-size: 58px;
					line-height: 2.2;
					color: #FFFFFF;
					opacity: 0.6;
					font-family: 'Baskerville Old Face';
				}
			}

			&__btn {
				height: 38px;
				line-height: 38px;
				padding: 0 28px;
				font-size: 14px;
				text-align: center;
				color: #f7f7f7;
				display: inline-block;
				opacity: 0.6;
				border: 1px solid #FFFFFF;

				&:hover {
					background-color: #c49a6c
				}
			}
		}

	}

	@media(max-width:500px) {
		.home .page-screen-0 {
			display: none;

			&.wap {
				display: block;
			}

			&-slidesNav {
				bottom: 20px;
			}

			&-prev {
				display: none;
				left: 10px;
				top: 60%;

				img {
					width: 70%;
				}
			}

			&-next {
				display: none;
				right: 10px;
				top: 60%;

				img {
					width: 70%;
				}
			}
		}
	}

	@media(max-width:2000px) {
		.home .page-screen-0 {
			height: 1080px;
		}
	}

	@media(max-width:1900px) {
		.home .page-screen-0 {
			height: 970px;
		}
	}
</style>