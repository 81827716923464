export default {
  lang:'tw',
  指数差价合约: "指數差價合約",
  无需持有基础资产: "無需持有基礎資產，靈活交易，",
  掌控全球机遇: "掌控全球機會",
  即刻开户: "立即開戶",
  美股指数差价合约: "美股指數差價合約",
  点差大幅度降低: "點差大幅降低，流動性增加，享受覆蓋全球市場的低成本交易",
  美国道琼斯工业平均指数: "US Dow Jones Industrial Average Index Spot CFD",
  美国标准普尔500指数: "US S&P 500 Index Spot CFD",
  美国纳斯达克100指数: "US NASDAQ-100 Index Spot CFD",
  香港恒生指数: "Hong Kong Hang Sheng Index Spot CFD",
  中国A50指数:"China A50 Index Spot CFD",
  降幅: "-Reduction Percentage (%)",
  亚洲时间: "Asian Session",
  欧洲时间: "European Session",
  美国时间: "US Session",
  品种: "Symbol",
  指数描述: "Description",
  指数点差: "Spreads in Index Points",
  开设模拟账户: "開設模擬帳戶",
  开设真实账户: "開設真實帳戶",
  产品优势: "產品優勢",
  无需持有基础资产:
    "無需持有基礎資產，低成本，靈活槓桿，讓您在市場漲跌中抓住每個機會",
  灵活高杠杆: "靈活高槓桿",
  杠杆高达: "槓桿高達100:1",
  放大每笔交易收益: "放大每筆交易收益，靈活管理風險",
  实时弹性定价: "即時彈性定價",
  根据全球市场波动实时调整: "根據全球市場波動即時調整",
  确保您每笔交易享受最优交易条件: "確保您每筆交易享有最優交易條件",
  极致的交易速度: "極致的交易速度",
  平均执行速度20毫秒: "平均執行速度20毫秒",
  助您抢占市场先机: "幫助您搶佔市場先機",
  完全透明的交易: "完全透明的交易",
  无隐藏费用或额外成本: "無隱藏費用或額外成本",
  确保您每笔交易的透明和公平: "確保您每筆交易的透明度和公平",
  全球指数交易深度: "全球指數交易深度",
  查看所有产品: "查看所有產品",
  指数差价合约相关问题: "指數差價合約相關問題",
  list: [
    {
      title: "1.什麼是指數CFD？",
      content: ` 指數CFD，一般指的是股票指數CFD，可以反映股票指數的價格變化並提供價格變動帶來的獲利或虧損。
                        <br /><br />

                        相較於單一的股票投資，股票指數反映了一組具有代表性股票的走勢，是一國某個產業甚至是整體經濟的晴雨表。
                        例如德國DAX
                        30指數，是由全國30支最具代表性的藍籌股組成，反映了德國股市的整體狀況。
                        <br /><br />

                        透過指數CFD，可以將對單一股票複雜的判斷簡化為分析整體市場情緒，從而分散風險，並避免個股極端的價格波動，整體的安全性更高。`,
    },
    {
      title: "2、指數CFD可以交易什麼？",
      content: ` 指數CFD可以交易全球股票指數。 <br /><br />

                        EBC甄選全球主流指數，包括道瓊指數、DAX 30指數、STOXX
                        50指數、標普500指數和日經225指數等。
                        只需要一個EBC帳戶，就可以在全球眾多市場中進行選擇，並透過EBC極具競爭力的點差和靈活的槓桿比率，在全球眾多極其活躍的市場中取得成功。`,
    },
    {
      title: "3、指數CFD交易槓桿？",
      content: `EBC可以為指數CFD交易提供最高100倍的槓桿，這是在合規和監管範圍內所允許的最大槓桿比例，充分考慮了安全性和資金利用率的平衡
                        。`,
    },
    {
      title: "4、指數CFD合約量如何規定？",
      content: `不同指數對應的合約量也是不同的。 <br /><br />

                        EBC提供全球最主流的股指CFD，其中100GBP（英國富時100）、200AUD（澳洲標普200）、CNIUSD（中國A50指數）、D30EUR（德國DAX30）、E50EUR（歐洲STOXX50）、F40EUR（德國DAX30）、E50EUR（歐洲STOXX50）、F40EUR（法國CAC40）
                        、HSIUSD（香港50指數）、NASUSD（美國納斯達克100指數）、U30USD（美國股票30指數）一手合約量是10。
                        <br /><br />

                        225JPY（日經225指數）、SPXUSD（標普500指數）一手合約量是100。
                        <br /><br />

                        指數CFD的最小手數均為0.1手。`,
    },
    {
      title: "5、指數CFD交易有哪些優勢？",
      content: `提高資金利用率<br />

                        指數交易的槓桿通常比較低，但透過指數CFD，可以佔用較少的資金參與交易。
                        但記住，槓桿和風險是相伴而行的，在進行槓桿交易時一定要保持部位控制。
                        <br /><br />

                        雙向交易 <br />

                        股票指數反映了股市的整體走向。
                        透過指數CFD，可以多空雙向交易，獲得更大獲利空間。
                        <br /><br />

                        分散風險 <br />

                        股票指數往往是選出最具代表性的一籃子股票編製的，是某一個市場中最優股票的集合。
                        單一股票的走勢往往無法對指數產生直接的影響，有效分散了市場風險。`,
    },
    {
      title: "6、如何學習參與指數CFD交易？",
      content: `由於指數CFD反映的是一攬子股票的走勢，所以我們需要關注的是市場整體，而非局部。
                        <br /><br />

                        一般來說，股票指數和央行的貨幣政策、一國的經濟數據直接相關。
                        例如，如果一國央行實施的是緊縮性貨幣政策，市場會擔心市場上缺乏資金，進而打壓股市；同樣的，如果一國經濟數據不佳，會壓低市場的投資預期，進而打壓股市。`,
    },
  ],
  每个认真交易的人: "每個認真交易的人，都值得被認真對待",
};
